import React from "react"

function Intro() {

  return(
    <>
    <div className="intro-overlay">

        <div className="logo-handwritten">

          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1610.09 569.44" height="30px"><defs></defs>
            <defs>
              <clipPath id="signature-clip">
                <path className="a" d="M259.35,5c25,0,44.46,9.9,58.5,29.7,8.82,14,13.32,30.42,13.32,48.78v5.4q0,48.06-33.3,123.29c-3.6,5.22-6.84,7.92-9.9,7.92h-1.08c-5.4-1.08-8.1-4.85-8.1-11,0-6.48,2.52-14.4,7.56-23.94a7.29,7.29,0,0,1,4.86-2,7.64,7.64,0,0,1,4,1.44h.18q22.95-59.13,22.86-97.92c0-33.12-12.24-54.72-36.72-65A83.15,83.15,0,0,0,256.65,18q-40.77,0-100.07,24.84c-16.74,7.2-34.38,16.92-53.28,29-10.62,6.84-22.86,16.74-36.9,29.52-30.24,30.24-45.18,60.84-45.18,92v.9c0,27,10.8,55.79,32.4,85.85a306,306,0,0,0,49.68,49.14q45.36,35.64,71.81,58.86c23.76-14.94,55.08-30.24,94.32-45.9,37.44-13.86,65.52-20.88,84.6-20.88,4.14.18,6.12,1.26,6.12,3.24v.18c0,1.8-11,2.34-16.92,3.06-18.9,1.44-50,11-93.06,28.62-31.5,14.22-54.9,26.46-70.38,36.72v.18c24.84,22.68,37.26,45.72,37.26,69.47q0,38.88-48.42,66.6c-16.73,8.46-32,12.6-46.07,12.6h-5c-17.82,0-31-6.66-39.6-20.34a40.87,40.87,0,0,1-4.5-18c0-28.62,30.6-64.61,92-108.53v-.18a495.11,495.11,0,0,0-48.77-40c-16.56-12.42-32.76-25.74-48.24-39.6C53.08,300,43.9,289.58,41,284.18a183.08,183.08,0,0,1-19.26-31.86c-9.54-22.14-14.22-41-14.22-57V191q0-53.73,55.8-105.84c18-15.48,35.46-27.9,52-37.26a388,388,0,0,1,55.25-25.74C203.37,10.59,230.55,5,252.33,5ZM103.84,530.41c2.52.9,7,1.44,14,1.8,18.18,0,36.36-5.94,54.89-17.64C194.19,499.45,205,482.35,205,463.09c0-20.69-11.52-41.93-34.56-63.53-8.64,5-20.69,14-36.17,26.82-34.2,29.7-51.3,55.43-51.3,76.85C83,516.91,90,526.09,103.84,530.41Z"/>
                <path className="a" d="M497.13,466.51c-2.16-11.34-3.05-25-3.05-40.85q0-77.76,39.59-223.56Q546.9,153.78,552,133l-20,38.52C502.54,227.84,467.44,286.16,414,348.8c-19.44,20.7-34.38,31-45.18,31-12.6,0-19.62-11-19.62-24.48,0-12.06,3.42-28.8,10.08-50.22,2-5.94,4.14-8.28,8.28-9.54,2.88,0,5.4.9,5.4,7,0,2.52-2.7,13-8.1,31.14-2.16,8.82-3.24,16-3.24,22,0,6.3,1.8,10.62,5,12.78a9.79,9.79,0,0,0,3.06.72c10.26,0,21.24-5.76,45.18-29.88,19.08-21.6,34.38-41.4,46.26-59.58,26.82-40,48.95-77.39,65.87-112,2-3.42,7.2-14,15.48-31.68C551,117.15,558.33,99,564.27,81.87a7.12,7.12,0,0,1,6.12-3.06c5.94,1.26,6.84,3.24,6.84,7,0,1.62-4,15.3-11.88,40.86q-16.2,49.14-34.92,113.39-28.62,105.3-28.62,175.14a298.21,298.21,0,0,0,3.24,43.2c3.78,0,5.22,2,5.22,7.55a3.66,3.66,0,0,1-2,3.42c-1.44,2.7-3.24,4-5.75,4C498.75,473.35,497.13,470.29,497.13,466.51ZM380.68,272.3c0,4.32-3.24,7.2-9.72,8.46h-1.08c-2.16,0-3.42-1.26-3.6-3.78,0-4.68,3.24-7.92,9.9-9.36h1.26C379.6,267.62,380.68,269.24,380.68,272.3Z"/>
                <path className="a" d="M557.8,344.66v-1.8c.18-3.24.72-7,1.26-10.8a202.65,202.65,0,0,1,8.1-32.94c1.8-2.34,3.42-3.6,5.22-3.6,3.6,0,5.4.54,5.58,5.58a206,206,0,0,0-8.28,33.12,70.12,70.12,0,0,0-1.08,11.34c0,6.84,1.62,11.34,4.86,13.32a5.25,5.25,0,0,0,2.7,1.08c7,0,14-5,30.78-28.62,13.5-19.26,27.18-42.66,41.4-70,1.08-1.44,2.16-2,2.88-2,1.08,0,1.44.18,1.44,1.26v.72c-.36,1.44-5.94,12.6-16.92,33.84q-40,72.63-63.9,72.72C562.3,367.88,557.8,360,557.8,344.66Z"/>
                <path className="a" d="M663.28,361.58a47.54,47.54,0,0,1-1.08-11.34c0-14.22,5.76-35.64,12.6-53.46a8.52,8.52,0,0,1,7-4.5c4.14,0,6.3,3.06,6.3,6.48a8.85,8.85,0,0,1-1.26,4.14c-8.46,19.8-14.22,38.88-14.22,51.84,0,10.62,4.14,15.12,11.7,15.12,7,0,16.2-4.68,27.72-14.22,11.52-9.36,27-27,31.14-32.4a7.4,7.4,0,0,1,1.8-1.8,2.26,2.26,0,0,1,1.08-.18c.89,0,1.25.36,1.25,1.26a5.33,5.33,0,0,1-1.79,2.88C736,337.46,722.68,352.22,711,361.94c-11.7,9.54-22,14.4-30.24,14.4C672.64,376.34,666.16,372.74,663.28,361.58ZM684.88,266c0-2.16,5-7.56,6.66-8.82,3.78-3.06,5.58-3.78,7.38-3.78,1.08,0,1.62.9,1.62,2.16a9.66,9.66,0,0,1-.9,3.42c-.72,1.44-5,5.76-7.2,7-2,1.26-3.6,2-4.86,2C686,268,684.88,267.26,684.88,266Z"/>
                <path className="a" d="M617.38,543.19a53,53,0,0,1-4-20.7c0-16.74,6.12-34.92,18.36-54.18,3.24-5.39,9-12.6,16.92-21.77q17.82-18.09,24.3-22.68c20.52-16.74,37.26-28.08,65.7-44.1,4.14-25.2,6.12-47.16,6.12-66.06,0-4.5-.18-9.54-.54-14.76a4.21,4.21,0,0,1-3.06-4.14c0-1.8,1.08-2.88,3.24-3.6a20.37,20.37,0,0,0,4.32-2.16l1.08-.18c4.14,0,6.3,1.26,6.3,5.76l.54,15.84c0,18.9-.72,34-4.86,62.1H752Q806.29,345,859.83,321.44l1.62-.18c1.26.18,1.8.54,1.8,1.44,0,2.34-5,5.4-15.3,9.36-34.91,16.2-67.31,32.4-97.37,49-10.26,54-26.46,100.25-43.74,130.49A166.16,166.16,0,0,1,687,539.77c-7.38,7.74-12.42,12.24-14.94,13.5-9.72,6.48-18,9.72-25.2,9.72C633.58,563,624.76,558.49,617.38,543.19Zm3.42-21.42c0,11.34,4,20.34,11.7,27.36a22.34,22.34,0,0,0,13.5,4.68c12.42,0,25.74-9.72,40.14-29.34,7.38-11.16,12.78-20.52,16.38-28.44,8.1-16.2,15.84-36.9,23.76-62.27,4.14-13.68,7.92-28.62,11.34-45h-.18a311.56,311.56,0,0,0-36.72,22c-10.26,7-22.14,16.74-35.64,29.34-10.8,11-18.36,19.61-22.86,26.09a166.77,166.77,0,0,0-13.14,22c-5.4,12.78-8.28,23.58-8.28,32.58ZM749.32,263.3c2.88,0,5,1.8,6.3,5.22v1.8c0,3.06-2,5.4-5.76,6.84a8.83,8.83,0,0,1-2,.18c-4.32-.72-6.48-2.88-6.48-6.3v-.36c0-3.42,2-5.76,5.94-7.2a3.51,3.51,0,0,1,1.44-.18Z"/>
                <path className="a" d="M917.25,307.22c1.8,0,2.88,1.62,3.42,4.86,0,27.72,8.64,41.4,26.1,41.4,11.7,0,26.82-6.48,45.54-19.44l14.76-12.42a9.17,9.17,0,0,1,2-.72c1.62.18,2.34.9,2.34,2.34s-4.5,5.94-13.68,13.32c-13,9.9-23.4,16.2-31,18.72-9.18,4-17.28,5.76-24.48,5.76-13.5,0-22.86-7.92-28.44-23.76q-3.24-8.64-3.78-8.64c-1.08,0-5.76,3.42-14.58,10.26-11.16,7.74-20.88,11.52-29,11.52h-.19c-6.83,0-11-3.06-12.77-9.18a12.91,12.91,0,0,1-.18-2.34v-1.62c0-7.74,4.68-15.12,14-22.32a42.81,42.81,0,0,1,24.3-7h.36c5.94,0,12.24,1.26,19.44,3.42v-.18c2-2.7,4-4,5.4-4Zm-50.58,35.64h1.26c7.74,0,19.44-7.2,35.1-21.24,0-.36.54-.9,1.62-2.16a70,70,0,0,0-15.3-2c-13.14,0-22.31,4.68-27.53,13.86a15.92,15.92,0,0,0-1.08,4.68C860.74,339.8,862.71,342.14,866.67,342.86Z"/>
                <path className="a" d="M1228.29,8.07c5.22.9,7.92,3.06,7.92,6.66,0,1.44-5.4,16.56-16.2,45.72q-47.79,131-84.6,263.87-23,84-43.92,185.75h.36q126.09-67.77,238-67.85a346.25,346.25,0,0,1,75.42,7.92c6.3,3.24,9.36,6.65,9.36,10.25,0,1.27-1.08,2-3.06,2.34-14.4-2.7-27-4.68-38-6.3a460.67,460.67,0,0,0-48.24-2.88q-117.16,0-235.43,68a7.52,7.52,0,0,1-3.24.54c-4-1.08-5.94-3.24-5.94-6.3v-.36c4.68-25.74,12.78-63.54,23.94-113.21A3186.35,3186.35,0,0,1,1207.41,55.77c4.68-12.78,7.2-19.62,7.2-20.88-4.14-1.08-6.12-3.24-6.12-6.12V27.33c1.08-4,4.5-9.18,10.08-15.48,2.88-2.52,5.76-3.78,8.46-3.78Z"/>
                <path className="a" d="M1312.53,358.52c-4.32-6.12-5-11.34-5-17.46,0-8.64,2-17.82,11.7-30.06,10.44-13.14,22.86-17.64,31.32-17.64,7.92,0,12.24,4.32,12.24,10.44,0,8.28-7.2,23.94-46.26,31l-.36,5.94c0,15.48,9.54,23.76,28.08,23.76,21.6,0,58-11.88,93.06-32.58a139.71,139.71,0,0,0,13.14-8.82,7.23,7.23,0,0,1,3.42-1.44,1.91,1.91,0,0,1,1.62,1.62c0,.9-1.45,2.34-4.15,4.14-37.79,25.38-80.45,43.38-107.81,43.38C1329.81,370.76,1319,367.34,1312.53,358.52Zm35.82-44.28a18,18,0,0,0,2.7-2.7c1.8-2.34,2.7-4.14,2.7-5.94,0-2.34-2.34-3.6-5.4-3.6-4.32,0-9.54,2.34-15.48,7-1.26,1.08-2.88,2.52-4.86,4.5,0,.18-5.22,5.94-6.66,8.28l-2.16,4C1332.51,323.24,1341.33,320.18,1348.35,314.24Z"/>
                <path className="a" d="M1509.27,307.22c1.8,0,2.88,1.62,3.42,4.86,0,27.72,8.64,41.4,26.1,41.4,11.7,0,26.82-6.48,45.53-19.44l14.76-12.42a9.17,9.17,0,0,1,2-.72c1.62.18,2.34.9,2.34,2.34s-4.5,5.94-13.68,13.32c-13,9.9-23.39,16.2-31,18.72-9.18,4-17.28,5.76-24.48,5.76-13.5,0-22.86-7.92-28.44-23.76q-3.24-8.64-3.78-8.64c-1.08,0-5.76,3.42-14.58,10.26-11.16,7.74-20.88,11.52-29,11.52h-.18c-6.84,0-11-3.06-12.78-9.18a12.91,12.91,0,0,1-.18-2.34v-1.62c0-7.74,4.68-15.12,14-22.32a42.79,42.79,0,0,1,24.3-7h.36c5.94,0,12.24,1.26,19.44,3.42v-.18c2-2.7,4-4,5.4-4Zm-50.58,35.64H1460c7.74,0,19.44-7.2,35.1-21.24,0-.36.54-.9,1.62-2.16a70,70,0,0,0-15.3-2c-13.14,0-22.32,4.68-27.54,13.86a16.25,16.25,0,0,0-1.08,4.68C1452.75,339.8,1454.73,342.14,1458.69,342.86Z"/>
              </clipPath>
            </defs>

            <g clipPath="url(#signature-clip)" strokeWidth="90">
              <path className="start" fill="none" stroke="#88a376" strokeMiterlimit="10"
              d="M303,257c0,0,44.7-52.7,49.3-102.7c4.7-50,4-60.7,4-60.7s1.3-39.3-36-52S231,45,231,45s-86,28-112,54.7
              c0,0-64.7,56.7-68,88s-4,84,18,106.7C91,317,110.3,353,165,387s76.7,85.3,76.7,85.3s6.3,30.7-7.7,49c-13.3,17.4-37,35.7-44.3,35.7
              c-7.3,0-36.7,13.3-52.7,7.3S111,541,111,541s-4-25.3,3.3-34.7c2-2.6,13.6-20.1,29-35.3c14.6-14.4,32-29.3,32-29.3l49-34
              c0,0,122.7-63.3,156.7-56.7l12-74.7c0,0,38,17.3,34,17.3s-34.7,14.7-34.7,14.7L403,317c0,0-18,48-15.3,64.7
              c2.7,16.7,11.3,20,11.3,20s6,5.3,22-8c16-13.3,33.3-31.3,33.3-31.3s78-101.3,80-116c2-14.7,91.3-158.7,91.3-158.7l-26-14.7
              l-13.3,105.3c0,0-38.7,115.3-36.7,124.7s-40,168-12.7,201.3c27.3,33.3,29.3-86.7,29.3-86.7l25.3-151.3l25.3,28c0,0-26,59.3-22.7,72
              c0,0-2.7,12.7,2,16c4.7,3.3,2.7,9.3,13.3,10c10.7,0.7,84.7-104,80-114.7c-4.7-10.7,64-12,64-12l-42,34l9.3,14.7
              c0,0-17.3,33.3-16.7,40.7l-5.3,19.3c0,0-0.7,22,6,23.3c6.7,1.3,19.3,3.3,24.7-2c5.3-5.3,42.7-32,42-37.3
              C771,353,761,279.7,761,279.7l16.7-13.3c0,0,10,91.3,4,110c-6,18.7-4,24-4,24s-10.7,63.3-18,78.7c-7.3,15.3-21.3,57.3-28,62.7
              C725,547,720.3,563,714.3,565s-9.3,8-9.3,8s-2.7,9.3-16.7,10.7c-14,1.3-22.7-1.3-26.7-3.3s-14.9-25.2-13.3-34.7
              c3.3-20,29.5-73.6,57.3-92.7c40-27.3,91.3-58,91.3-58l88-42l6.7-44l68,16.7l-22,26.7l-24,16.7c0,0-14.7,4-19.3,4
              c0,0-11.3-4-5.3-13.3c6-9.3,12.7-19.3,20.7-19.3s39.3,0.7,38,6c-1.3,5.3,6,24,6,24s3.3,10,9.3,10s9.3,4.7,9.3,4.7s49.3-6,72-40
              c22.7-34,187.3-326.7,187.3-326.7s37.3,2,36.7,5.3c-0.7,3.3-60,170.7-60,170.7s-104,349.3-90,364.7s-38,6.7-38,6.7
              s101.3-57.3,108.7-57.3c0,0,161.3-65.3,267.3-18.7s-54-57.3-54-57.3l-116-59.3l74.7-19.3c0,0,28.7-11.9,29.3-18
              c1-9-3.5-10.7-3.5-10.7s-15.9-1.4-20.7,4c-2.3,2.7-9.2,8.4-13.7,14c-5.6,7-9.5,14-9.5,14s-1.3,17.3,3.3,21.3c4.7,4,16,18.7,24,16.7
              s56.7-9.3,68-18c11.3-8.7,32.7-17.3,32.7-17.3l23.3-62.7l66,24l-35.3,36c0,0-25.3,18-29.3,17.3c-4-0.7-16.7-4.7-14.7-11.3
              s14-20.7,14-20.7s46-9.3,46,10c0,19.3,15.3,30,15.3,30s46,10,78-39.3s4-21.3,4-21.3"/>
            </g>
          </svg> 

        </div>

        <div className="overlay-bar"></div>
        <div className="overlay-bar"></div>
        <div className="overlay-bar"></div>
    </div>
    </>
  )
  
}

export default Intro